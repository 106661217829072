import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Appearance, Stripe, StripeCardNumberElement, StripeElements } from '@stripe/stripe-js';

import { Formdata } from '@app/forms/formly/formly-utils';
import { scrollTo } from '@app/utils/utils';
import { scrollToFirstError } from '@app/forms/formly/base-form/base-form';
import { environment } from '../../../environments/environment';
import { errorsFormTexts } from '../formly/validators/validators';

@Component({
  selector: 'stripe-form',
  templateUrl: './stripe-form.component.html',
  styleUrls: ['./stripe-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StripeFormComponent implements OnInit, OnChanges, OnDestroy {
  stripe: Stripe | null = null;
  elements: StripeElements | null = null;

  cardNumber: StripeCardNumberElement | null = null;
  cardNumberErrors: string | null = null;
  cardNumberComplete: boolean;

  cardExpiryErrors: string | null = null;
  cardExpiryComplete: boolean;

  cardCvcErrors: string | null = null;
  cardCvcComplete: boolean;

  addressErrors: string | null = null;
  addressComplete: boolean;

  disabled: boolean;

  @Input() readAndAccept: boolean;
  @Input() clientSecret: string | null = null;
  @Input() portalLink: string;

  @Output() back = new EventEmitter();
  @Output() formSubmit = new EventEmitter<Formdata>();
  @Output() formSubmitCallback = new EventEmitter<Formdata>();
  @Output() setReadAndAcceptError = new EventEmitter();

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.initializeStripe();
  }

  initializeStripe() {
    void import('@stripe/stripe-js')
      .then((module) => module.loadStripe(environment.STRIPE_PUBLIC_KEY, { locale: 'en' }))
      .then((stripe) => {
        this.stripe = stripe;

        if (this.stripe) {
          this.elements = this.stripe.elements({ appearance });

          this.initCardNumberField();
          this.initCardExpiryField();
          this.initCardCvcField();
          this.initAddressField();
        }
      });
  }

  initCardNumberField() {
    if (!this.elements) return;

    const cardNumber = this.elements.create('cardNumber', { style });

    cardNumber.mount('#card-number');

    cardNumber.on('change', (event) => {
      this.addressErrors = null;

      if (event.error) {
        this.cardNumberErrors = event.error.message;
      } else {
        this.cardNumberErrors = null;
      }

      this.cardNumberComplete = event.complete;
      this.changeDetectorRef.detectChanges();
    });

    cardNumber.on('blur', () => {
      if (!this.cardNumberErrors && !this.cardNumberComplete) {
        this.cardNumberErrors = errorsFormTexts.cardNumberErrors;
        this.changeDetectorRef.detectChanges();
      }
    });

    this.cardNumber = cardNumber;
  }

  initCardExpiryField() {
    if (!this.elements) return;

    const cardExpiry = this.elements.create('cardExpiry', { style });

    cardExpiry.mount('#card-expiry');

    cardExpiry.on('change', (event) => {
      this.addressErrors = null;

      if (event.error) {
        this.cardExpiryErrors = event.error.message;
      } else {
        this.cardExpiryErrors = null;
      }

      this.cardExpiryComplete = event.complete;

      this.changeDetectorRef.detectChanges();
    });

    cardExpiry.on('blur', () => {
      if (!this.cardExpiryErrors && !this.cardExpiryComplete) {
        this.cardExpiryErrors = errorsFormTexts.cardExpiryErrors;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  initCardCvcField() {
    if (!this.elements) return;

    const cardCvc = this.elements.create('cardCvc', { style });

    cardCvc.mount('#card-cvc');

    cardCvc.on('change', (event) => {
      this.addressErrors = null;

      if (event.error) {
        this.cardCvcErrors = event.error.message;
      } else {
        this.cardCvcErrors = null;
      }

      this.cardCvcComplete = event.complete;
      this.changeDetectorRef.detectChanges();
    });

    cardCvc.on('blur', () => {
      if (!this.cardCvcErrors && !this.cardCvcComplete) {
        this.cardCvcErrors = errorsFormTexts.cardCvcErrors;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  initAddressField() {
    if (!this.elements) return;

    const address = this.elements.create('address', { mode: 'billing' });

    address.mount('#address');

    address.on('change', (event) => {
      this.addressErrors = null;

      this.addressComplete = event.complete;
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['clientSecret']?.currentValue) {
      void this.handlePayment();
    }
  }

  async handlePayment() {
    if (!this.stripe || !this.clientSecret || !this.cardNumber) return;

    const addressElementValue = await this.elements?.getElement('address')?.getValue();
    const address = addressElementValue?.value;

    const { error, paymentIntent } = await this.stripe.confirmCardPayment(this.clientSecret, {
      payment_method: {
        card: this.cardNumber,
        billing_details: { ...address },
      },
    });

    if (error) {
      console.log(error.message || error);

      this.addressErrors = errorsFormTexts.addressErrors;
      this.disabled = false;

      this.changeDetectorRef.detectChanges();
    } else if (paymentIntent && paymentIntent.status === 'succeeded') {
      this.formSubmitCallback.emit();
    }
  }

  submit() {
    const isValid =
      this.cardNumberComplete &&
      !this.cardNumberErrors &&
      this.cardExpiryComplete &&
      !this.cardExpiryErrors &&
      this.cardCvcComplete &&
      !this.cardCvcErrors &&
      this.addressComplete;

    if (isValid && this.readAndAccept) {
      this.disabled = true;

      this.formSubmit.emit();
    } else if (!isValid) {
      if (!this.addressComplete) {
        this.addressErrors = errorsFormTexts.required;
      }

      this.disabled = false;

      if (this.elements) {
        this.elements.getElement('cardNumber')?.focus();
        this.elements.getElement('cardExpiry')?.focus();
        this.elements.getElement('cardCvc')?.focus();
        setTimeout(() => this.elements?.getElement('cardCvc')?.blur(), 100); // todo why blur() doesnot work?
      }
      this.scrollToFirstError();
    } else {
      this.setReadAndAcceptError.emit(true);
      scrollTo('#read-and-accept');
    }

    const lead_portal_link = this.portalLink;
    window.track({ event_name: 'submit_stripe', lead_portal_link });
  }

  scrollToFirstError() {
    scrollToFirstError();
  }

  ngOnDestroy() {
    if (this.elements) {
      this.elements.getElement('cardNumber')?.destroy();
      this.elements.getElement('cardExpiry')?.destroy();
      this.elements.getElement('cardCvc')?.destroy();
      this.elements.getElement('address')?.destroy();
    }
  }
}

const style = {
  base: {
    color: '#0A1D3D',
    fontSize: '14px',
    lineHeight: '20px',
    '::placeholder': {
      color: '#898390',
    },
  },
  invalid: {
    color: '#0A1D3D',
  },
  complete: {
    color: '#0A1D3D',
  },
};

const appearance: Appearance = {
  disableAnimations: true,
  variables: {
    spacingUnit: '4px',
    fontFamily: '"Inter", "Roboto", arial, sans-serif',
  },
  rules: {
    '.Label': {
      color: '#898390',
      fontSize: '14px',
      lineHeight: '20px',
    },
    '.Input': {
      color: '#0A1D3D',
      fontSize: '14px',
      lineHeight: '20px',
      border: '1px solid #E2E0E3',
      borderRadius: '8px',
      backgroundColor: '#FFFFFF',
      boxShadow: '0 0 0 0 #000000',
      transition: 'border-color 200ms ease-out, background-color 200ms ease-out',
    },
    '.Input:hover': {
      backgroundColor: '#F1F1F1',
    },
    '.Input:focus': {
      border: '1px solid #E2E0E3',
      boxShadow: '0 0 0 0 #000000',
    },
    '.Input--invalid': {
      color: '#0A1D3D',
      border: '1px solid #E52E2E',
      boxShadow: '0 0 0 0 #000000',
    },
    '.Input--invalid:hover': {
      backgroundColor: '#FDEEEE',
    },
  },
};
