<div class="mb-4 mx-4 mx-md-0 d-flex align-items-center justify-content-between">
  <h2 class="semi-bold">Payment details</h2>
</div>

<card class="d-flex flex-column p-4">
  <p class="text-jazlyn-700 mb-1">Debit/Credit card number</p>
  <div [ngClass]="{ 'error': cardNumberErrors }" class="input" id="card-number"></div>
  <p *ngIf="cardNumberErrors" class="error mt-1">{{ cardNumberErrors }}</p>

  <div class="d-flex w-100 mt-4">
    <div class="d-flex flex-column mr-2 w-100">
      <p class="text-jazlyn-700 mb-1">Expiration date</p>
      <div [ngClass]="{ 'error': cardExpiryErrors }" class="input" id="card-expiry"></div>
      <p *ngIf="cardExpiryErrors" class="error mt-1">{{ cardExpiryErrors }}</p>
    </div>

    <div class="d-flex flex-column ml-2 w-100">
      <p class="text-jazlyn-700 mb-1">Security code</p>
      <div [ngClass]="{ 'error': cardCvcErrors }" class="input" id="card-cvc"></div>
      <p *ngIf="cardCvcErrors" class="error mt-1">{{ cardCvcErrors }}</p>
    </div>
  </div>

  <div class="mt-4" id="address"></div>
</card>

<div
  *ngIf="addressErrors"
  class="d-flex align-items-center mx-4 mx-md-0 mt-4 mb-2 mb-md-0 errors-infoblock"
  data-id="error"
>
  <img src="assets/icons/error.svg" width="20" height="20" alt="info" class="mb-auto" />
  <p class="ml-2 text-jazlyn-100">{{ addressErrors }}</p>
</div>

<ng-content></ng-content>

<div class="mt-4 mt-md-6 mx-4 mx-md-0 d-flex flex-column-reverse flex-md-row">
  <button
    (click)="back.emit()"
    class="button secondary mt-4 mt-md-0 d-flex align-items-center justify-content-center"
    data-id="back"
  >
    <img src="assets/icons/chevron_left_blue.svg" alt="chevron" class="mr-2" />
    Back
  </button>

  <button
    (click)="submit()"
    [disabled]="disabled"
    class="button ml-md-auto d-flex align-items-center justify-content-center"
    data-id="submit"
  >
    Pay
    <img src="assets/icons/chevron_right_white.svg" alt="chevron" class="ml-2" />
  </button>
</div>
