export const environment = {
  production: true,
  GRAPHQL_URL: 'https://pymhyb6d36.execute-api.eu-west-2.amazonaws.com/production/portal/graphql',
  GRAPHQL_PROTECTED_URL: 'https://pymhyb6d36.execute-api.eu-west-2.amazonaws.com/production/a-portal/graphql',
  GRAPHQL_SCHEMA_URL: 'https://pymhyb6d36.execute-api.eu-west-2.amazonaws.com/production/portal/schema',
  BUILD_QUERY_KEY: '6a004d54ad43#14',
  STRIPE_PUBLIC_KEY: 'pk_test_51QhuQqFybTRmg9eiwA5eI2dvtrXnWJq2h0b5Qvi6oAbkcAfXAR9nXhqTa6741DMRTTBQBmCV5CYccNUgaZ9BSLCQ00GJVH7m5e',
  CLOUDFRONT_API: 'https://d32xlaew8n9fq7.cloudfront.net/production/portal/graphql',
  TRACKING_URL: 'https://tr.businessclassconsolidator.com',
  AWS_PROJECT_REGION: 'eu-west-2',
  AWS_COGNITO_REGION: 'eu-west-2',
  AWS_USER_POOLS_ID: 'eu-west-2_RlqmhW89a',
  AWS_USER_POOLS_WEB_CLIENT_ID: '30me7ppb3ae597lud0rra11dgd',
  OAUTH_DOMAIN: 'production-portal.auth.eu-west-2.amazoncognito.com',
  AMPLIFY_REDIRECT_SIGN_IN: 'https://q.bcconsolidator.com/login',
  AMPLIFY_REDIRECT_SIGN_OUT: 'https://q.bcconsolidator.com/logout',
};