import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, EMPTY, map, Observable, of, Subscription, switchMap, take } from 'rxjs';

import { PortalLeadService } from '@app/services/portal-lead/portal-lead.service';
import {
  AuthorizedDataNode,
  PortalLeadNode,
  PortalUserVerificationNode,
} from '@app/services/api/api.types';
import { bccUrl, passwordSalt } from '@app/utils/constants';
import { StateService } from '@app/services/state/state.service';
import { PopupsService } from '@app/ui/services/popups.service';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { AuthService } from '@app/services/auth/auth.service';
import { ApiService } from '@app/services/api/api.service';
import { ApiProtectedService } from '@app/services/api/api.protected.service';

@Component({
  selector: 'lead-wrapper-page',
  templateUrl: './lead-wrapper-page.component.html',
  styleUrls: ['./lead-wrapper-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeadWrapperPageComponent implements OnInit, OnDestroy {
  CognitoHostedUIIdentityProvider = CognitoHostedUIIdentityProvider;

  portalLead$: Observable<PortalLeadNode | null>;
  router$: Subscription;

  showLoginButton: boolean;

  verification: PortalUserVerificationNode;
  verificationPassed: boolean;
  verificationError: string | null;

  email: string;

  activation: PortalUserVerificationNode;
  activationPassed: boolean;
  activationError: string | null;

  constructor(
    private portalLeadService: PortalLeadService,
    private changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private popupsService: PopupsService,
    private stateService: StateService,
    private authService: AuthService,
    private apiService: ApiService,
    private apiProtectedService: ApiProtectedService,
    private router: Router
  ) {}

  @ViewChild('signInTemplate') signInTemplate: TemplateRef<unknown>;
  @ViewChild('verificationTemplate') verificationTemplate: TemplateRef<unknown>;
  @ViewChild('continueWithEmailTemplate') continueWithEmailTemplate: TemplateRef<unknown>;
  @ViewChild('activationCodeTemplate') activationCodeTemplate: TemplateRef<unknown>;

  ngOnInit() {
    this.router$ = this.router.events.subscribe(this.handleShowLoginButtonVisibility.bind(this));

    this.getPortalLead();
  }

  getPortalLead() {
    this.portalLeadService.setPortalLead(null);

    const portalLink = this.activatedRoute.snapshot.paramMap.get('lead-id') || '';

    this.portalLeadService.getPortalLead({ portalLink });

    this.portalLead$ = this.portalLeadService.portalLead$.pipe(
      map((portalLead) => {
        this.stateService.patchState({ portalLink });

        if (portalLead) {
          if (portalLead.flightRequest?.isLost) {
            window.location.href = bccUrl;
          }

          this.handleShowLoginButtonVisibility();
        }

        return portalLead;
      }),
      switchMap((portalLead: PortalLeadNode | null) => {
        if (portalLead) {
          return this.initSessionUser(portalLead);
        }
        return of(portalLead);
      }),
      catchError((error: Error) => {
        console.log(error.message || error);
        return EMPTY;
      })
    );
  }

  initSessionUser(portalLead: PortalLeadNode | null) {
    return this.authService.hasSession().pipe(
      switchMap((hasSession) => {
        if (hasSession) {
          return this.authService.initSessionUser(portalLead);
        }

        throw new Error('Invalid session: initSessionUser');
      }),
      switchMap(() => this.getAuthorizedData()),
      switchMap(() => of(portalLead)),
      catchError((error: Error) => {
        console.log(error.message || error);
        return of(portalLead);
      })
    );
  }

  getAuthorizedData() {
    const portalLink = this.activatedRoute.snapshot.paramMap.get('lead-id') || '';

    const variables = { portalLink };

    return this.apiProtectedService
      .authorizedData(variables)
      .pipe(map((data) => this.handleAuthorizedData(data)));
  }

  handleAuthorizedData(data: AuthorizedDataNode) {
    this.stateService.patchAuthorizedData({ passengers: data.passengers });

    this.stateService.patchAuthorizedData({ creditCards: data.creditCards });

    this.stateService.patchAuthorizedData({ clientBalanceAmount: data.clientBalanceAmount });

    this.verification = data.verification;

    if (this.verification.required) {
      this.showVerificationModal();
    } else {
      this.tryPreselectOption();
    }
  }

  tryPreselectOption() {
    const url = new URL(window.location.href);
    const portalLink = url.searchParams.get('portalLink');

    if (portalLink) {
      setTimeout(() => {
        document.getElementById(`submit-${portalLink}`)?.click();
        void this.router.navigate([], { queryParams: {} });
      });
    }
  }

  showVerificationModal() {
    this.popupsService.showModal(this.verificationTemplate);
  }

  validateCode(verificationCode: string) {
    this.verificationPassed = false;
    this.verificationError = null;

    const portalLink = this.activatedRoute.snapshot.paramMap.get('lead-id') || '';

    const variables = { portalLink, verificationCode };

    this.apiProtectedService
      .portalAcceptVerification(variables)
      .pipe(take(1))
      .subscribe(({ data, errors }) => {
        if (errors?.length) {
          this.verificationError = errors[0].message;
          this.changeDetectorRef.detectChanges();
        } else {
          this.verificationPassed = true;
          this.changeDetectorRef.detectChanges();

          this.handleAuthorizedData(data.portalAcceptVerification.result);
        }
      });
  }

  portalSendVerificationEmail() {
    const portalLink = this.activatedRoute.snapshot.paramMap.get('lead-id') || '';

    const variables = { portalLink };

    this.apiProtectedService
      .portalSendVerificationEmail(variables)
      .pipe(take(1))
      .subscribe(({ result }) => {
        this.hideModal();
        this.handleAuthorizedData(result);

        this.changeDetectorRef.detectChanges();
      });
  }

  handleShowLoginButtonVisibility() {
    const routeLevel = this.router.url.split('/').filter((v) => !!v)?.length; // todo probably bug
    this.showLoginButton = routeLevel === 1;
  }

  showSignInModal() {
    this.popupsService.showModal(this.signInTemplate);
  }

  continueWith(provider: CognitoHostedUIIdentityProvider) {
    const url = `${window.location.href}`;
    void this.authService.federatedSignIn(provider, url);
  }

  continueWithEmail() {
    this.hideModal();
    this.popupsService.showModal(this.continueWithEmailTemplate);
  }

  sendEmail(email: string) {
    this.activationError = null;
    this.email = email;

    const portalLink = this.activatedRoute.snapshot.paramMap.get('lead-id') || '';

    const variables = { portalLink, email };

    this.apiService
      .sendPasswordToEmail(variables)
      .pipe(take(1))
      .subscribe(({ errors }) => {
        if (errors?.length) {
          this.activationError = errors[0].message;
        } else {
          this.hideModal();

          this.activation = {
            emailMasked: this.email,
            emailSentAt: new Date().toUTCString(),
          };

          this.popupsService.showModal(this.activationCodeTemplate);
        }

        this.changeDetectorRef.detectChanges();
      });
  }

  validateNewEmailCode(password: string) {
    this.activationPassed = false;
    this.activationError = null;

    void this.authService
      .signIn(this.email, `${password}${atob(passwordSalt)}`)
      .pipe(
        take(1),
        map(() => {
          this.activationPassed = true;

          setTimeout(() => window.location.reload(), 1000);

          this.changeDetectorRef.detectChanges();
        }),
        catchError((error: Error) => {
          console.log(error.message || error);

          this.activationError = error.message;

          this.changeDetectorRef.detectChanges();

          return EMPTY;
        })
      )
      .subscribe();
  }

  signOut() {
    void this.authService.signOut();
  }

  hideModal() {
    this.popupsService.hideLast();
  }

  ngOnDestroy(): void {
    this.router$?.unsubscribe();
  }
}
